import React from 'react';
import HomeTemplate from '../../templates/HomeTemplate';

const Page: React.SFC = (props) => (
  <HomeTemplate
    {...props}
    header="The best wiki software for enterprise teams"
    subheader="Build, organize, and collaborate with your team wiki everywhere in the world with Slab."
    metaTitle="The best wiki software for enterprise teams - Slab"
    metaDescription="Build, organize, and collaborate with your team wiki everywhere in the world with Slab."
    hideNavbarLinks
  />
);

export default Page;
